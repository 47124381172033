import { GetStaticProps, GetStaticPropsContext } from "next";
import { loadTranslations } from "@shared/server";
import { NotFoundPage } from "@features";

export const getStaticProps: GetStaticProps = async ({
  locale,
}: GetStaticPropsContext) =>
  loadTranslations(["notFound", "components"], locale);

export default NotFoundPage;
